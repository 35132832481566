$('.char-count').on('keyup', function()
{
    $(this).next('span').remove();
    let char=$(this).val().length;
    let maxlength=$(this).attr('maxlength');
    let char_remaining=maxlength-char;

    let span=$('<span>');
    span.text(char_remaining+" karakter megengedett még");
    $(span).insertAfter($(this));
})
