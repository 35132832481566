window.listOfficesToContactForm = function () {
    if (document.querySelector('#type_id') && document.getElementsByName('item_type_id').length) {
        let inputs = document.getElementsByName('filtered_list_group[]');
        let iterationLength = inputs.length;
        let task_ids = [];
        let typeSelect = document.querySelector('#type_id');
        let type_id = typeSelect.value;
        typeSelect.addEventListener('change', function () {
            document.querySelector('#office-list').remove();
        });
        for (let i = 0; i < iterationLength; i++) {
            if (inputs[i].checked) {
                task_ids.push(inputs[i].value);
            }
            inputs[i].addEventListener('change', function () {
                if (inputs[i].checked) {
                    task_ids.push(inputs[i].value);
                } else {
                    let index = task_ids.indexOf(inputs[i].value);
                    task_ids.splice(index, 1);
                }
                ajax(task_ids, type_id);
            });
        }
        ajax(task_ids, type_id);
    }
}

function ajax(task_ids, type_id) {
    if (task_ids.length) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/item/contact-form/get-offices',
            dataType: 'json',
            data: {'task_ids': task_ids, 'type_id': type_id},
            success: function (offices) {
                let officeDiv = document.querySelector('#office-list');
                if (officeDiv) officeDiv.remove();
                listOffices(offices);
            }
        });
    }
}

function listOffices(offices) {
    let taskDivs = document.querySelector('#filtered_list_group');
    let officesDiv = createAnyElement('div', {id: 'office-list'});
    taskDivs.insertAdjacentElement('afterend', officesDiv);
    officesDiv.innerHTML += '<hr><h4>Irodák kiválasztása</h4>';
    let checkBoxDiv = createAnyElement('div', {class: 'group-element'});
    createMainCheckBox(offices.length, checkBoxDiv);
    taskDivs.insertAdjacentElement('afterend', officesDiv);
    createCheckBoxes(offices, checkBoxDiv);
    officesDiv.append(checkBoxDiv);
    selectAllCheckboxes();
}

function createMainCheckBox(countCheckboxes, checkBoxDiv) {
    if (countCheckboxes > 2) {
        let mainCheckboxDiv = createAnyElement('div', {class: 'checkbox-control custom-control custom-switch main-control'});
        let mainCheckboxInput = createAnyElement('input', {
            class: 'custom-control-input select_all',
            name: 'select_all',
            id: 'checkbox-group-3',
            type: 'checkbox'
        });
        let mainCheckBoxLabel = createAnyElement('label', {
            class: 'custom-control-label',
            for: 'checkbox-group-3'
        }, 'Összes kiválasztása');
        mainCheckboxDiv.append(mainCheckboxInput, mainCheckBoxLabel);
        checkBoxDiv.append(mainCheckboxDiv);
    }
}

function createCheckBoxes(offices, checkBoxDiv) {
    let oldOfficeIds = getOldOfficeIds();
    let iterationLength = offices.length;
    for (let i = 0; i < iterationLength; i++) {
        let div = createAnyElement('div', {class: 'checkbox-control custom-control custom-switch'});
        let input = createAnyElement('input', {
            name: 'office_ids[]',
            id: 'office-' + offices[i].id,
            value: offices[i].id,
            class: 'custom-control-input checkbox',
            type: 'checkbox'
        });
        let label = createAnyElement('label', {
            class: 'custom-control-label',
            for: 'office-' + offices[i].id,
        }, offices[i].name);
        if (oldOfficeIds.includes(offices[i].id)) input.setAttribute('checked', 'checked');
        div.append(input, label);
        checkBoxDiv.append(div);
    }
}

function getOldOfficeIds() {
    let oldInputs = document.getElementsByName('old_office_ids[]');
    let iterationLength = oldInputs.length;
    let oldOfficeIds = [];
    for (let i = 0; i < iterationLength; i++) {
        oldOfficeIds.push(parseInt(oldInputs[i].value));
    }
    return oldOfficeIds;
}
