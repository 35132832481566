window.stringCapitalizeFirstLetter=function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
window.docReady = function (fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}