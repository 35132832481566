$('#new-tag-save').on('click', function () {
    let tag_name = $('#new_tag').val();
    let checkbox_div = ($("#tag-list").children(':first'));

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/articles/tag/new-tag',
        dataType: "json",
        data: {tag_name: tag_name},
        success: function (tag) {
           appendNewCheckBox(tag);
           $('#new_tag').val('');
        },
    });
});
function appendNewCheckBox(tag)
{
    let checkbox_div = ($("#tag-list").children(':first'));
    let class_name=checkbox_div.attr('class');
    let input = checkbox_div.children("input").clone();
    let label = checkbox_div.children('label').clone();
    input.attr({
        id: `tag-${tag.id}`,
        value: tag.id,
    })
    label.attr({
        for: `tag-${tag.id}`,
    });
    label.text(tag.name);
    let appendDiv=$(`<div class="${class_name}">`);
    appendDiv.append(input, label);
    $('#tag-list').append(appendDiv);
}