let facebook = document.querySelector('#has_facebook');
if (facebook && facebook.value !== '0') {
    let ogTitleVal = document.querySelector('#og_title_value').value;
    createOgTitleField(ogTitleVal);
}
let facebook_checkbox=document.querySelector('#facebook');
if(facebook_checkbox) {
    facebook_checkbox.addEventListener('click', function(element) {
        let og_title_input=document.querySelector('#og_title_input');
        if(element.target.checked) createOgTitleField();
        else if (og_title_input) og_title_input.innerHTML='';

    });
}
// $('#facebook').on('change', function () {
//     if ($(this).is(':checked')) {
//         createOgTitleField();
//     } else $('#og_title_input').empty();
// });

function createOgTitleField(ogTitleVal='') {
    let formGroupDiv =createFormGroupDiv();
    let label = createAnyElement('label', {
        for: 'og_title',
        title: 'Facebook megosztáskor megjelenő cím. Nem kell figyelni arra, hogy tartalmazza a kulcsszavakat, lényeg hogy a felhasználó tudja, miről szól az aloldal',
        'data-toggle': 'tooltip'
        }, 'Og title');

    let input = createTextInput({'name': 'og_title', value: ogTitleVal});
    formGroupDiv.append(label, input);
    document.querySelector('#og_title_input').append(formGroupDiv);
}