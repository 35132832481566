let button = document.querySelector('#bookingPricing-addblock');
if(button) {
    let fields = [
        {
            type:'input',
            name:'name',
            label:'Név*',
            placeholder:'Név'
        },
        {
            type:'input',
            label:'Ár*',
            placeholder:'Ár',
            name:'price'
        },
        {
            type:'input',
            name:'unit',
            label:'Mértékegység*',
            placeholder:'/hó'
        }
    ]
    addBlock(button, 'Tétel', fields);
}