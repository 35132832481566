window.optionSelect = function () {
    let service_select = document.querySelector('#service_id');
    if(service_select) {
        service_select.addEventListener('change', function (element) {
            optionSelectAjax(element.target.value);
        });
        let old_option_value;
        let old_option_input = document.querySelector('input[name=old_option_id]');
        if(old_option_input) old_option_value=parseInt(old_option_input.value);
        else old_option_value=null;
        if (service_select.value) {
            optionSelectAjax(service_select.value, old_option_value)
        }
    }
}
function optionSelectAjax(service_id, old_option = null) {
    document.querySelector('#service_options').innerHTML = '';
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/item/package-option-select',
        dataType: 'json',
        data: {service_id: service_id},
        success: function (options) {
            createOptionsSelect(options, old_option);
        }
    });
}

function createOptionsSelect(options, old_option) {
    let form_group_div = createFormGroupDiv();
    let select = createSelect({id: 'option_id', name: 'option_id'});
    for (let i = 0; i < options.length; i++) {
        let option = createAnyElement('option', {value: options[i].id}, options[i].name);
        if (old_option && old_option === options[i].id) option.setAttribute('selected', 'selected');
        select.append(option)
    }
    form_group_div.append(createAnyElement('label', {for: 'select_id'}, 'Opció választás'), select);
    document.querySelector('#service_options').append(form_group_div);
    listPrices();
}
