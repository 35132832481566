let blocks_div = document.querySelector('#blocks');

window.addBlock = function (button, block_title, fields) {
    if (blocks_div) {
        button.addEventListener('click', function () {
            let loop = document.querySelectorAll('.blocks.box-block.accordion-block').length;
            createBlock(fields, block_title, loop);
            removeBlock(`blocks-${loop}`);
        });
        let old_blocks_div = document.querySelector('#old_blocks');
        if (old_blocks_div) createBlockFromOld(old_blocks_div, fields, block_title);
        old_blocks_div.innerHTML='';
    }
}
function createBlockFromOld(old_blocks_div, fields, block_title) {
    for (let i = 0; i < old_blocks_div.childElementCount; i++) {
        fields=fields.filter(element=>element.name!=='id');
        let oldValues=[];
        let inputs = old_blocks_div.children[i].children;
        for (let j = 0; j < inputs.length; j++) {
            let input_name_attr = inputs[j].getAttribute('name');
            let input_name = input_name_attr.substring(input_name_attr.lastIndexOf('[') + 1, input_name_attr.length - 1);
                oldValues.push({
                    name: input_name,
                    value:inputs[j].value
            });
        }
        if(oldValues.find(element=>element.name==='id')) {
            fields.push({
                type:'hiddenInput',
                name:'id'
            })
        }
        createBlock(fields, block_title, i, oldValues);
        removeBlock(`blocks-${i}`);
    }
}

function createBlock(fields, title, loop, oldValues=null) {
    const BLOCKS_DIV = document.querySelector('#blocks');
    let one_block_div = createAnyElement('div', {class: 'blocks box-block accordion-block', id: 'block' + loop});
    one_block_div.append(createAnyElement('h4', {}, title + ' ' + (loop + 1)));
    createBlockFields(fields, one_block_div, loop, oldValues);
    one_block_div.append(createAnyElement('button', {
        class: 'removeBlock btn remover-btn btn-danger',
        type: 'button',
        id: `blocks-${loop}`
    }, 'Eltávolítás'))
    BLOCKS_DIV.append(one_block_div);
    getUserTypeAjaxAndCallEditor();
}

window.removeBlock = function (remove_block_id) {
    let remove_button = $('#' + remove_block_id);
    if (remove_button) {
        remove_button.on('click', function (element) {
            $("#deleteModal").modal('show');
            $("#confirmDelete").on('click', function () {
                let blockNumber = element.target.id.substring(7);
                $('#block' + blockNumber + '').remove();
                $('#deleteModal').modal('hide');
            });
        });
    }
}

function createBlockFields(fields, one_block_div, loop, oldValues) {
    for (let i = 0; i < fields.length; i++) {
        let func_name = 'add' + stringCapitalizeFirstLetter(fields[i].type);
        let value;
        if(oldValues) value=oldValues.find(oldValue=>oldValue.name===fields[i].name);
        else value=null;
        createBlockFieldsFunc[func_name](fields[i], one_block_div, loop, value);
    }
}

let createBlockFieldsFunc = {
    addInput: function (field, one_block_div, loop, oldValue) {
        blockInput(field, one_block_div, loop, oldValue);
    },
    addTextarea: function (field, one_block_div, loop, oldValue) {
        blockTextarea(field, one_block_div, loop, oldValue);
    },
    addSelect: function (field, one_block_div, loop, oldValue) {
        blockSelect(field, one_block_div, loop, oldValue);
    },
    addCheckbox: function (field, one_block_div, loop, oldValue) {
        blockCheckbox(field, one_block_div, loop, oldValue);
    },
    addHiddenInput: function (field, one_block_div, loop, oldValue) {
        blockHiddenInput(field, one_block_div, loop, oldValue);
    }
}

function blockInput(field, one_block_div, loop, oldValue) {
    let input_params = {
        id: `block-${field.name}-${loop}`,
        name: `blocks[${loop}][${field.name}]`,
        placeholder: field.placeholder,
    }
    if(oldValue) input_params.value=oldValue.value;
    let form_group_div = createFormGroupDiv();
    form_group_div.append(createAnyElement('label', {for: `block-${field.name}-${loop}`}, field.label), createTextInput(input_params));
    one_block_div.append(form_group_div);
}

function blockTextarea(field, one_block_div, loop, oldValue) {
    let textarea_params = {
        id: `block-${field.name}-${loop}`,
        name: `blocks[${loop}][${field.name}]`,
        placeholder: field.placeholder,
    }
    if(field.class) textarea_params.class=field.class;
    let form_group_div = createFormGroupDiv();
    let value='';
    if(oldValue)value=oldValue.value;
    form_group_div.append(createAnyElement('label', {for: `block-${field.name}-${loop}`}, field.label), createTextArea(textarea_params, value));
    one_block_div.append(form_group_div);
}

function blockSelect(field, one_block_div, loop, oldValue) {

    let select_attributes = {
        id: `block-${field.name}-${loop}`,
        name: `blocks[${loop}][${field.name}]`,
    };
    let select = createSelect(select_attributes);
    let options = field.options;
    for (let i = 0; i < options.length; i++) {
        let option=createAnyElement('option', {value: options[i].value}, options[i].text);
        let selectedCondition;
        if(oldValue) {
            if(typeof options[i].value==='string') selectedCondition=options[i].value===oldValue.value;
            else selectedCondition=options[i].value===parseInt(oldValue.value);
        }

        if(selectedCondition) option.setAttribute('selected', 'selected');
        select.append(option);
    }
    let form_group_div = createFormGroupDiv();
    form_group_div.append(createAnyElement('label', {for: `block-${field.name}-${loop}`}, field.label), select);
    one_block_div.append(form_group_div);
}
function blockCheckbox(field, one_block_div, loop, oldValue) {
    let checkbox_params = {
        id: `block-${field.name}-${loop}`,
        name: `blocks[${loop}][${field.name}]`,
        value: 1,
    }

    if(oldValue) checkbox_params.checked = parseInt(oldValue.value);
    let checkbox = createCheckBox(checkbox_params, field.label)
    let hiddenInputAttributes = {
        type: 'hidden',
        name: checkbox_params.name,
        value: 0
    }
    let checkBoxDiv = appendCheckBoxToDiv(createCheckBoxDiv(), checkbox);
    let hiddenInput = createAnyElement('input', hiddenInputAttributes);
    one_block_div.append(hiddenInput, checkBoxDiv);

}

function blockHiddenInput(field, one_block_div, loop, oldValue) {
    let hiddenInputAttributes = {
        type: 'hidden',
        id: `block-${field.name}-${loop}`,
        name: `blocks[${loop}][${field.name}]`,
        value: oldValue.value
    };
    one_block_div.append(createAnyElement('input', hiddenInputAttributes));
}