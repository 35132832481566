let service_div = document.querySelector('#city_services');
if (service_div) {
    let city_select = document.querySelector('#city_id');
    city_select.addEventListener('change', function (element) {
        getServicesAjax(element.target.value);
    });
    let old_service= document.querySelector('input[name=old_service_id]');
    let old_service_val;
    if(old_service) old_service_val=parseInt(old_service.value);
    else old_service_val=null;
    if (old_service || city_select.value) {
        getServicesAjax(city_select.value, old_service_val);
    }
}

function getServicesAjax(city_id, old_service_id = null) {
    service_div.innerHTML = '';
    if(city_id) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '/item/package-service-select',
            dataType: 'json',
            data: {city_id: city_id},
            success: function (services) {
                createServiceSelect(services, old_service_id);
                optionSelect();
            }
        });
    }

}

function createServiceSelect(services, old_service_id) {
    let form_group_div = createFormGroupDiv();
    let select = createSelect({id: 'service_id', name: 'service_id'});
    for (let i = 0; i < services.length; i++) {
        let option = createAnyElement('option', {value: services[i].id}, services[i].name);
        if(old_service_id && old_service_id===services[i].id) option.setAttribute('selected', 'selected');
        select.append(option);
    }
    form_group_div.append(createAnyElement('label', {for: 'select_id'}, 'Szolgáltatás választás'), select);
    service_div.append(form_group_div);
}