window.createAnyElement = function (name, attributes = {}, text = null) {
    let element = document.createElement(name);
    for (let attribute in attributes) {
        if (attributes.hasOwnProperty(attribute)) {
            element.setAttribute(attribute, attributes[attribute]);
        }
    }
    if (text) element.textContent = text;
    return element;
}
window.createTextInput = function (attributes = {}) {
    attributes.class = 'form-control'
    attributes.type = 'text';
    return createAnyElement('input', attributes);
}
window.createTextArea = function(attributes={}, value) {
    if(attributes.class) attributes.class+=' form-control'
    else attributes.class='from-control';
  return createAnyElement('textarea', attributes, value);
};
window.createSelect = function (attributes = {}) {
    attributes.class = 'form-control';
    let select= createAnyElement('select', attributes);
    select.append(createAnyElement('option', {value:''}, '---Válasszon---'));
    return select;
}

window.createFormGroupDiv = function () {
    return createAnyElement('div', {class: 'form-group'});
}
window.createCheckBoxDiv = function () {
    return createAnyElement('div', {class: 'checkbox-control custom-control custom-switch'});
}

window.createCheckBox = function (attributes = {}, text = null,array=false) {
    attributes.class = 'custom-control-input checkbox';
    attributes.type = 'checkbox';
    if (!attributes.value) attributes.value = 1;
    let checkBoxElements=[];
    if(array) checkBoxElements.push(createAnyElement('input', {type: 'hidden', value: 0, name: attributes.name}),)
    if(attributes.checked) attributes.checked='checked';
    else delete attributes.checked
    checkBoxElements.push(createAnyElement('input', attributes),
        createAnyElement('label', {
            class: 'custom-control-label',
            for: attributes.id ? attributes.id : attributes.name
        }, text));
    return checkBoxElements;
}
window.appendCheckBoxToDiv = function (div, checkboxElements) {
    for (let i in checkboxElements) {
        if (checkboxElements.hasOwnProperty(i)) {
            div.append(checkboxElements[i]);
        }
    }
    return div;
}

