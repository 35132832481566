//booking pricelist item show selected city's prices
if (document.querySelector('#booking_city_id')) {
    select_change('booking_city_id', {
        url: '/item/booking_price_list/get_prices',
        checkbox_id: 'id',
        checkbox_value: ['name', 'price', 'unit'],
        title:'Árak kiválasztása'
    });
}
//contact_form item and office show selected office_type tasks
if (document.querySelector('#type_id')) {
    select_change('type_id', {
        url: '/office-types',
        checkbox_id: 'id',
        checkbox_value: ['name'],
        title:'Feladatkörök kiválasztása'
    });
}

//menu select list menus submenus
if(document.querySelector('#menu_select')) {
    window.menu_select_list=function() {
        select_change('menu_id', {
            url: '/item/menu_select/get_menus',
            checkbox_id: 'id',
            checkbox_value: ['name'],
            title:'Menüpontok kiválasztása'
        });
    }
}