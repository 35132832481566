$(function () {
    $('.filtered-list-group-order').sortable({
        header: ">li>p",
        handle: "p"
    });
    $('#new-filtered-list-group-order').on('click', function () {
        let values = getValues();
            $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url:'' ,
                    dataType: 'json',
                    data:{values:values} ,
                    success: function () {
                       window.location=document.referrer;
                    }
                });
    });
});

function getValues() {
    let values = [];
    let elements = $('.filtered-list-group-element');
    elements.each(function (key, value) {
        values.push($(value).data('id'));
    });
    return values;
}