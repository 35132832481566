let button=document.querySelector('#thumbnail-addblock');
if(button)
{
    let fields = [
        {
            type:'input',
            name:'title',
            label:'Cím*',
            placeholder: 'Cím'
        },
        {
            type:'input',
            name:'img_filename',
            label:'Kép fájl neve (a "thumbnails" mappában kell elhelyezni a fájlt!)*',
            placeholder:'filename.jpg'
        },
        {
            type:'input',
            name:'img_alt',
            label:'Kép alt*',
            placeholder:''
        },
        {
            type:'input',
            name:'img_title',
            label:'Kép title',
            placeholder:''
        },
        {
            type:'textarea',
            name:'text',
            label:'Szöveg*',
            placeholder: 'Szöveg',
            class:'content'
        },
        {
            type:'input',
            name:'cta_url',
            label:'Akció gomb link',
            placeholder:''
        },
        {
            type:'input',
            name:'cta_text',
            label:'Akció gomb szövege',
            placeholder:''
        }
    ]
    addBlock(button, 'Szöveges ikon blokk', fields);
}