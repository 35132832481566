window.listPrices = function () {
    let option_select = document.querySelector('#option_id');
    if (option_select) {
        let service_id=document.querySelector('#service_id').value;
        let city_id=document.querySelector('#city_id').value;

        option_select.addEventListener('change', function (element) {
            let option_id=element.target.value;
            getPricesAjax(option_id, service_id, city_id);
        });
        if(option_select.value) {
            getPricesAjax(option_select.value, service_id, city_id);
        }
    }
}

function getPricesAjax(option_id, service_id, city_id) {
         $.ajaxSetup({
                 headers: {
                     'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                 }
             });
             $.ajax({
                 type: 'POST',
                 url: '/item/package-list-prices',
                 dataType: 'json',
                 data: {option_id:option_id, service_id:service_id, city_id:city_id},
                 success: function (prices) {
                     addPrices(prices);
                 }

             });
}
function addPrices(prices)
{
    let priceListDiv=document.querySelector('#prices-list');
    priceListDiv.innerHTML='';
    priceListDiv.innerHTML='<p><b>Kiválasztott szolgáltatás árai:</b></p>';
    let priceListP=createAnyElement('p');
    for (const [key, value] of Object.entries(prices)) {
        priceListP.innerHTML+=(`${key}: ${value}<br>`);
    }
    priceListDiv.append(priceListP);
}