let fileDirectories = $('.file-directory');
if (fileDirectories.length) {
    fileDirectories.on('click', function (event) {
        // double click don't run twice
        if (!event.detail || event.detail === 1) {
            let selectedDir = $(this).text();
            document.querySelector('#delete-form-elements').innerHTML = '';
            document.querySelector('#upload-form-elements').innerHTML = '';
            $('#file-directories li').removeClass('active');
            $(this).addClass('active');
            addFileUploadButtons(selectedDir);
            getImgAjax(selectedDir);
        }
    });
}
let old_selected_dir = document.querySelector('#old_selected_dir');
if (old_selected_dir) {
    let selectedDir = old_selected_dir.value;
    addFileUploadButtons(selectedDir);
    document.querySelector(`#${selectedDir}`).classList.add('active');
    getImgAjax(selectedDir);
}

function getImgAjax(selectedDir) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '',
        dataType: 'json',
        data: {selectedDir: selectedDir},
        success: function ([images, imageLink]) {
            createImagesList(images, imageLink, selectedDir);
            deleteButton();
        }
    });
}

