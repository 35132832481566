let button=document.querySelector('#faq_category-addblock');
if(button) {
    let fields = [
        {
            type:'input',
            name:'name',
            label:'Kérdés*',
            placeholder:'Kérdés'
        },
        {
            type:'textarea',
            name:'text',
            label:'Válasz*',
            placeholder: 'Válasz',
            class:'content',
        }
    ]
    addBlock(button,  'Kérdés-válasz blokk', fields)
}