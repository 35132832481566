let button = document.querySelector('#office-addblock');
if (button) {
    let fields = [
        {
            type: 'input',
            name: 'name',
            label: 'Név*',
            placeholder:'Név'
        },
        {
            type: 'input',
            name: 'tel',
            label: 'Telefonszám',
            placeholder: '+36 20 123 4567',
        },
        {
            type: 'input',
            name: 'email',
            label: 'E-mail cím',
            placeholder: 'teszt@teszt.hu'
        }
    ];
    getPostsAndCallBlocks(fields);

}

function getPostsAndCallBlocks(fields) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/office/get-posts',
        dataType: 'json',
        data: {},
        success: function (posts) {
            fields=addSelectToFields(fields, posts);
            addBlock(button, 'Dolgozó', fields);
        }

    });
}

function addSelectToFields(fields, posts) {
    let iterationLength = posts.length;
    let selectOptions = [];
    for (let i = 0; i < iterationLength; i++) {
        selectOptions.push({text: posts[i].name, value: posts[i].id})
    }
    fields.push({
        type:'select',
        name:'post_id',
        label:'Beosztás*',
        options:selectOptions
    });
    return fields;
}