$(function()
{
    $('.block-orders').sortable({
        header: "> li > p",
        handle: "p",
    });
    function getBlockValues()
    {
        let blockValues=[];
        $('.block-orders>li').each(function(){
           blockValues.push({id:$(this).attr('id')})
        });
        return blockValues;
    }
    $('#new-block-order').on('click', function(){
        let blockModel=$('input[name=block_class]').val();
        blockOrderAjax(getBlockValues(), blockModel);
    });

    function blockOrderAjax(blockOrder, blockModel)
    {
            $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '',
                    dataType: 'json',
                    data: {blockOrder:blockOrder, blockModel:blockModel} ,
                    success: function () {
                        window.location=document.referrer;
                    }

                });
    }
})