let button = document.querySelector('#image_map-addblock');
if (button) {
    let fields = [
        {
            type: 'input',
            name: 'title',
            label: 'Title*',
            placeholder: 'Title'
        },
        {
            type: 'input',
            name: 'alt',
            label: 'Alt*',
            placeholder: 'Alt'
        },
        {
            type: 'input',
            name: 'link',
            label: 'Link*',
            placeholder: 'Link'
        },
        {
            type: 'input',
            name: 'coords',
            label: 'Koordináták*',
            placeholder: 'Koordináták'
        },
        {
            type: 'select',
            name: 'shape',
            label: 'Jelölt kép-terület formájának jellege*',
            options: [
                {
                    text: 'poly',
                    value: 'poly'
                },
                {
                    text: 'rect',
                    value: 'rect',
                },
                {
                    text: 'circle',
                    value: 'circle'
                }
            ]
        },
        {
            type: 'checkbox',
            name: 'target_blank',
            label: 'Új lapon nyílik meg?'
        }
    ];
    addBlock(button,  'Jelölt kép-terület blokk', fields)
}