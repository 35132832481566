let button=document.querySelector('#main_selector-addblock');
if(button) {
    let fields= [
        {
            type:'input',
            name: 'title',
            label: 'Cím*',
            placeholder:'Cím',
        },    {
            type:'input',
            name:'link_url',
            label: 'Link*',
            placeholder:'Link'
        },
        {
            type:'textarea',
            name:'text',
            label: 'Szöveg*',
            placeholder:'Szöveg',
            class:'content',
        }
    ];
    addBlock(button,  'Szöveges Blokk', fields)
}
