//List checkboxes witch affect select change

let append_div = document.querySelector('#filtered_list_group');

/**
 *
 * @param select_id
 * @param ajax_params
 */
window.select_change = function (select_id, ajax_params) {
    //old values
    let select_val = document.querySelector('#' + select_id).value;
    if (select_val) {
        ajax_params.data = {select_id: select_val};
        if(document.querySelector('#old_filtered_list_group')) ajax_params.checkbox_ids = getBlockIds();
        getCheckBoxValueAjax(ajax_params);
    }
    //select change
    document.querySelector('#' + select_id).addEventListener('change', function (element) {
        select_val = element.target.value;
        ajax_params.data = {select_id: select_val}
        getCheckBoxValueAjax(ajax_params);
    });
}

/**
 *
 * @param url
 * @param data
 * @param checkbox_id
 * @param checkbox_value
 * @param checkbox_ids
 * @param title
 */
function getCheckBoxValueAjax({url, data, checkbox_id, checkbox_value, checkbox_ids = null, title}) {
    append_div.innerHTML = '';
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data: data,
        success: function (checkbox_data) {
            createCheckBoxBlock(checkbox_data, checkbox_id, checkbox_value, append_div, checkbox_ids, title);
        }
    })
}

/**
 *
 * @returns {[]}
 */
function getBlockIds() {
    let hiddenInputs = document.querySelector('#old_filtered_list_group').children;
    let block_ids = [];
    for (let i = 0; i < hiddenInputs.length; i++) {
        block_ids.push(parseInt(hiddenInputs[i].value));
    }
    return block_ids;
}

function createMainControlDiv(all_checkbox_blocks, numberOfGroup, filteredListGroupDivEl) {
    if(all_checkbox_blocks > 2) {
        let mainControl = createCheckBoxDiv();
        mainControl.classList.add('main-control');
        let selectAllCheckboxInput = createAnyElement('input', {
            class: 'custom-control-input select_all',
            name: 'select_all',
            id: `checkbox-group-${numberOfGroup+1}`,
            type: 'checkbox',
        });
        let selectAllCheckboxLabel = createAnyElement('label', {
            class: 'custom-control-label',
            for: `checkbox-group-${numberOfGroup+1}`
        }, 'Összes  kiválasztása');
        mainControl.append(selectAllCheckboxInput, selectAllCheckboxLabel);
        filteredListGroupDivEl.append(mainControl);
    }
}
createCheckBoxBlock = function (checkbox_blocks, value, text, appendDiv, block_ids, title) {
    let numberOfGroup = document.querySelectorAll('.group-element').length;
    let filteredListGroupDivEl = createAnyElement('div', {
        id: `filtered-list-group-${numberOfGroup+1}`,
        class: 'group-element'
    })
    let all_checkbox_blocks = checkbox_blocks.length;
    createMainControlDiv(all_checkbox_blocks, numberOfGroup, filteredListGroupDivEl);

    let label_text;
    let countOfChecked = 0;
    for (let i = 0; i < all_checkbox_blocks; i++) {
        label_text = '';
        if (typeof text === 'object') {
            for (let j = 0; j < text.length; j++) {
                label_text += checkbox_blocks[i][text[j]];
                if (j !== text.length - 1) label_text += ' ';
            }
        } else label_text = text;
        let checked = false;
        if (block_ids && block_ids.includes(checkbox_blocks[i][value])) {
            checked = true;
            countOfChecked++;
        }
        let checkboxElements = createCheckBox({
            name: 'filtered_list_group[]',
            id: `filtered_list_group-${numberOfGroup+1}-${i}`,
            value: checkbox_blocks[i][value],
            checked: checked,
        }, label_text);
        let checkBoxDiv = appendCheckBoxToDiv(createCheckBoxDiv(), checkboxElements);
        filteredListGroupDivEl.append(checkBoxDiv);
    }
    appendDiv.append(filteredListGroupDivEl);

    let mainControlInput = document.querySelector(`#checkbox-group-${numberOfGroup+1}`)
    if(all_checkbox_blocks === countOfChecked && mainControlInput) {
        mainControlInput.setAttribute('checked', 'checked');
    }

    selectAllCheckboxes();
    appendDiv.prepend(createAnyElement('h4', {}, title));
    listOfficesToContactForm();
}







