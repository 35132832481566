if(document.querySelector('#extra_service_select')) {
    window.priceInformativeServiceSelect = function () {
        let serviceSelect=document.querySelector('#service_id');
        let cityId=document.querySelector('#city_id').value;
        serviceSelect.addEventListener('change', function() {
            if(this.value) {
                getExtraServicesAjax(cityId, this.value)
            }
        });
        if(serviceSelect.value) {
            getExtraServicesAjax(cityId, serviceSelect.value);
        }
    }
}
function getExtraServicesAjax(cityId, serviceId)
{
        $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type: 'POST',
                url: '/item/price_informative/get-extra-services',
                dataType: 'json',
                data:{city_id : cityId, service_id:serviceId} ,
                success: function (extraServices) {
                    listExtraServices(extraServices);
                }

            });
}
function listExtraServices(extraServices)
{
    let extraServiceTbody=document.querySelector("#extra-service-list tbody");
    extraServiceTbody.innerHTML='';
    let iterationLength=extraServices.length;

    for(let i=0; i<iterationLength; i++)
    {
        let gross = extraServices[i].gross;
        gross ? gross='bruttó' : gross='+ áfa';

        let tr=createAnyElement('tr', {});
        tr.append(
            createAnyElement('td', {}, extraServices[i].name),
            createAnyElement('td', {}, `[${extraServices[i].var_name}]`),
            createAnyElement('td', {}, extraServices[i].price + ' ' + extraServices[i].unit + ' ' + gross),
        );
        extraServiceTbody.append(tr);
    }
}
