window.renameFileEventListener = function(selectedDir)
{
    $('.img_name').dblclick(function(event) {
        let imgNameP=$(event.target);
        let imgName=imgNameP.text();
        let renameForm=document.getElementById('form-'+imgName);
        if(renameForm) renameForm.remove();
        let imgDiv=imgNameP.parent();
        imgDiv.append(createRenameImgForm(imgName, selectedDir));

    });
}
window.changeInputsVal = function () {
    $('.file-upload input[type="file"]').on('change', function () {

        $('.file-upload input[type="text"]').val($(this).val().slice(12));
    });
    $('span.file-upload-btn').on('click', function () {
        $('.file-upload input[type="text"]').val($('.file-upload input[type="file"]').val().slice(12));
    });
}
if(document.querySelector('.file-upload')) changeInputsVal();

window.createImagesList = function (images, imageLink, selectedDir = null) {
    let deleteFormElements = document.querySelector('#delete-form-elements');
    let fileListDiv = createAnyElement('div', {class: 'file-list', id: 'file-list'});
    if (selectedDir) createFileListHeader(fileListDiv, selectedDir);
    let galleryDiv = createAnyElement('div', {id: 'gallery', class: 'gallery'});
    let iterationLength = images.length;
    for (let i = 0; i < iterationLength; i++) {
        createImgDiv(images[i], imageLink, galleryDiv, selectedDir);
    }
    fileListDiv.append(galleryDiv);
    deleteFormElements.append(fileListDiv);
    if (selectedDir) deleteFormElements.append(createAnyElement('input', {
        type: 'hidden',
        name: 'selected_dir',
        value: selectedDir
    }));
    renameFileEventListener(selectedDir);
}

window.deleteButton = function () {
    document.querySelector('#delete-form-elements').append(createAnyElement('button', {
        type: 'button',
        class: 'deletebtn btn btn-danger',
        id: 'deleteImg'
    }, 'Eltávolítás'));
    showModalBeforeDelete();
}

window.addFileUploadButtons=function(selectedDir=null) {
    let fileUploadDiv = createAnyElement('div', {class: 'file-upload'});
    let formGroupDiv = createFormGroupDiv();
    let span = createAnyElement('span', {id: 'file-input', class: 'file-upload-btn btn btn-purple-outline'}, 'Fájl kiválasztása');
    span.append(createAnyElement('input', {type: 'file', class: 'validation', name: 'file'}));
    formGroupDiv.append(span, createAnyElement('input', {
        type: 'text',
        placeholder: 'Nincs fájl kiválasztva',
        readonly: ''
    }));
    fileUploadDiv.append(formGroupDiv, createAnyElement('button', {
        class: 'savebtn btn btn-success',
        type: 'submit'
    }, 'Feltöltés'));
    let uploadFormElements = document.querySelector('#upload-form-elements');
    uploadFormElements.append(fileUploadDiv);
    if (selectedDir) uploadFormElements.append(createAnyElement('input', {
        type: 'hidden',
        name: 'selected_dir',
        value: selectedDir
    }))
    changeInputsVal();
}

function createFileListHeader(fileListDiv, selectedDir) {
    let fileListHeader = createAnyElement('div', {id: 'file-list-header', class: 'heading'});
    fileListHeader.append(createAnyElement('h3', {}, `A(z) ${selectedDir} mappában található képek/fájlok`));
    fileListDiv.append(fileListHeader);
}
function createImgDiv(image, imageLink, galleryDiv, selectedDir) {
    let imgName = image.split('/').pop();
    let imgDiv = createAnyElement('div', {class: 'img-data'});
    imgDiv.append(
        createAnyElement('img', {src: `${imageLink}${image}`}),
        createAnyElement('p', {class:'img_name'}, imgName),
    );
    let checkbox = createAnyElement('div', {class: 'checkbox'});
    let label = createAnyElement('label');
    label.append(
        createAnyElement('input', {class: 'select-img', type: 'checkbox', name: 'images[]', value: imgName}),
        createAnyElement('span', {class: 'checkmark'})
    );
    checkbox.append(label);
    imgDiv.append(checkbox);
    galleryDiv.append(imgDiv);
}
function createRenameImgForm(imageName, selectedDir)
{
    let imgNameWithoutExtension=imageName.split('.')[0];
    let form=createAnyElement('form', {action:'/file-upload/rename', method:'POST', id:'form-'+imageName, class:'rename-form'});
    let label=createAnyElement('label', {for:'new_name_'+imageName}, 'Fájl átnevezése:');
    let renameInput=createTextInput({name:'new_name', id:'new_name'+imageName, value:imgNameWithoutExtension});
    let oldNameHIdden=createAnyElement('input', {type:'hidden', name:'old_name', value:imageName});
    let selectedDirHidden=createAnyElement('input', {type:'hidden', name:'selected_dir', value:selectedDir});
    let submitBtn=createAnyElement('button', {type:'submit', class:'btn savebtn btn-success'}, 'Mentés');
    let csrfTokenValue=document.getElementById('csrf_to_rename').value;
    let csrfToken=createAnyElement('input', {type:'hidden', name:'_token', value:csrfTokenValue});

    form.append(csrfToken, label,renameInput, oldNameHIdden, selectedDirHidden,submitBtn);
    return form;
}

function showModalBeforeDelete() {
    document.querySelector('#deleteImg').addEventListener('click', function () {
        setDeleteModalContent();
        $('#confirmDelete').modal('show');
    });
}

function setDeleteModalContent() {
    let checkedImg = getSelectedImg();
    if (checkedImg.length) {
        $('.modal-body h3').empty().append('Biztosan törlöd?');
        $('.modal-footer').empty().append('<button class="btn btn-secondary" data-dismiss="modal" type="button">Mégse</button>' +
            '<button class="btn btn-outline-danger" type="submit">Törlés</button>');
    } else {
        $('.modal-body h3').empty().append('Előbb válassz ki legalább egy fájlt!');
        $('.modal-footer').empty().append('<button class="btn btn-success" data-dismiss="modal" type="button">OK</button>');
    }

}

function getSelectedImg() {
    let images = document.querySelectorAll('.select-img');
    let checkedImg = [];
    let iterationLength = images.length;
    for (let i = 0; i < iterationLength; i++) {
        if (images[i].checked) checkedImg.push(images[i]);
    }
    return checkedImg;
}

