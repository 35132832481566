/** Menu form parent menu select if select submenu menu type */
let menu_type_id = document.querySelector('#menu_type_id');
if (menu_type_id) {
    menu_type_id.addEventListener('change', function (select) {
        let parent_id = document.querySelector('#parent_id');
        if (parseInt(select.target.value) === 3) getSubPagesAjax({parent_menu: true})
        else if (parent_id) {
            parent_id.parentElement.remove();
            document.querySelector('#hidden_parent_id').remove();
        }
    });
}
/** Menu form parent menu select from old value */
if (document.querySelector('#old_menu_type_id')) {
    getSubPagesAjax({parent_menu: true})
}
/** Duplicate menu form parent menu */
if (document.querySelector("#duplicate_id")) {
    getSubPagesAjax({duplicated: true});
}

/** Item form attachment select if radio button click (article or menu) */
let attachment_select = document.getElementsByName('attachment_type');
if (attachment_select) {
    for (let i = 0; i < attachment_select.length; i++) {
        attachment_select[i].addEventListener('click', function (element) {
            let attachment_id = document.querySelector('#attachment_id')
            if (attachment_id) {
                attachment_id.parentElement.remove();
            }
            let attachment_type = element.target.value;
            getSubPagesAjax({attachment_type: attachment_type});
        });
    }
}
/** Item form attachment select from old value */
let old_attachment_type = document.querySelector('#old_attachment_type');
if (old_attachment_type) {
    let attachment_type = old_attachment_type.value;
    if (attachment_type) getSubPagesAjax({attachment_type: attachment_type});
}
/** Menu-select item form menu select */
let menu_select = document.querySelector('#menu_select');
if (menu_select) {
    if (document.querySelector('#menu_select_item')) getSubPagesAjax({menu_select_item: true})
    else getSubPagesAjax({attachment_type: 'menu'})
}

/** Post parameter to MenuController@getOptionsSubPageSelect to get select options */
function getSubPagesAjax(data) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/menu/get-subpage-options',
        dataType: 'json',
        data: data,
        success: function (sub_pages) {
            createSubPageSelect(sub_pages, data)
        }
    });
}

function createSubPageSelect(sub_pages, data) {
    let select_vars = getSelectParameters(data);
    if (select_vars) {
        let fromGroupDiv = createFormGroupDiv();
        let label = createAnyElement('label', {for: select_vars.id, class: 'form_check_label'}, select_vars.label_text);
        let select = createSelect({id: select_vars.id, name: select_vars.id});
        for (let i in sub_pages) {
            if (sub_pages.hasOwnProperty(i)) {
                getOptionClass(sub_pages[i]);
                let option = createAnyElement('option', {value: sub_pages[i][select_vars.option_value]}, sub_pages[i][select_vars.option_text]);
                option.className += getOptionClass(sub_pages[i]);
                if(data.attachment_type==='menu' && !sub_pages[i].url) option.setAttribute('disabled', 'disabled');
                if (select_vars.old_option_id) {
                    if (sub_pages[i][select_vars.option_value] === select_vars.old_option_id) option.setAttribute("selected", 'selected');
                }
                select.append(option);
            }
        }
        fromGroupDiv.append(label, select);
        if (select_vars.append_element) select_vars.append_element.append(fromGroupDiv);
        if(data.menu_select_item) menu_select_list();
    }
}

function getSelectParameters(data) {
    let select_vars;
    if (data.parent_menu || data.duplicated) select_vars = getParentMenuParameters();
    else if (data.attachment_type && document.querySelector('#attachment_select_form')) select_vars = getAttachmentParameters(data.attachment_type);
    else select_vars = getMenuIdParameters();
    return select_vars;
}

function getParentMenuParameters() {
    let hiddenParent=document.querySelector('#hidden_parent_id');
    return {
        id: 'parent_id',
        label_text: 'Szülő menü',
        option_value: 'id',
        option_text: 'name',
        append_element: document.querySelector('#parent_select'),
        old_option_id: hiddenParent ? parseInt(hiddenParent.value) : null,
    }
}

function getAttachmentParameters(attachment_type) {
    let vars = {
        id: 'attachment_id',
        option_value: 'id',
        append_element: document.querySelector('#attachment_select_form'),
        old_option_id: parseInt(document.querySelector('#old_attachment_id').value),
    }
    vars.label_text = 'Aloldalhoz csatolás'
    if (attachment_type === 'menu') vars.option_text = 'name'
    else vars.option_text = 'title'

    return vars;

}

function getMenuIdParameters() {
    return {
        id: 'menu_id',
        label_text: 'Menühöz csatolás',
        option_value: 'id',
        option_text: 'name',
        append_element: menu_select,
        old_option_id: parseInt(document.querySelector('#old_menu_id').value),
    }
}

function getOptionClass(sub_page) {
    let class_name = '';
    if (sub_page.hidden_page === 1) class_name += ' hidden-in-list ';
    if (sub_page.parent_id === null) class_name += 'main-menu';
    return class_name;
}
