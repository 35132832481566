let button=document.querySelector('#categories_with_text-addblock');
if(button) {
    let fields = [
        {
            type: 'input',
            name: 'title',
            label: 'Ikonos szöveg címe',
            placeholder: 'Ikonos szöveg címe'
        },
        {
            type: 'input',
            name: 'icon',
            label: 'Ikon neve*',
            placeholder: 'minta.svg#minta-id'
        },
        {
            type: 'input',
            name: 'link',
            label: 'Link*',
            placeholder: '/aloldal'
        },
        {
            type: 'textarea',
            name: 'text',
            label: 'Szöveg*',
            placeholder: 'Szöveg',
            class:'content',
        }
    ]
    addBlock(button, 'Szöveges ikon blokk', fields)
}
