if(document.querySelector('#popup-upload-forms')) {
    $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '',
            dataType: 'json',
            data: {} ,
            success: function ([images, imageLink]) {
                createImagesList(images, imageLink);
                deleteButton();
                addFileUploadButtons();
            }
        });
}