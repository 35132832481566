$(function () {
    let main_menus = $(".main-menus-order");
    let sub_menus = $(".sub-menus-order");
    main_menus
        .accordion({
            header: "> li > p",
            heightStyle: "content",
            collapsible: true
        });
       main_menus.sortable({
            handle: "p",
        });

    sub_menus
        .sortable({
            header: "> li > p",
            handle: "p"
        });

    function getMainValues()
    {
        let mainOrder = [];
        main_menus.children("li").each(function(){
            mainOrder.push($(this).attr("id"));
        });
        return mainOrder;

    }

    function getSubValues() {
        let subOrders = {};
        sub_menus.each(function(){
            let main_id=$(this).attr("id");
            subOrders[main_id]=[];
            $(this).children("li").each(function(){
                subOrders[main_id].push($(this).attr("id"));
            });
        });
        return subOrders;
    }

    $(".newOrder").click(function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: 'POST',
            url: '',
            dataType: "json",
            data: {main_orders:getMainValues(), sub_orders:getSubValues()},
            success: function (data) {
                window.location.href = data
            },
        });

    })
});

