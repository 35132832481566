window.selectAllCheckboxes = function () {
    const SELECT_ALL_INPUTS = document.querySelectorAll('.select_all');
    SELECT_ALL_INPUTS.forEach(input => input.addEventListener('change', toggleCheckedAll));

    const CHECKBOXES = document.querySelectorAll('.checkbox');
    CHECKBOXES.forEach(checkbox => checkbox.addEventListener('change', toggleChecked));
}
selectAllCheckboxes();
function toggleCheckedAll() {
    let selectCheckboxGroup = this.parentNode.parentNode;
    let allSiblingCheckboxControls = selectCheckboxGroup.querySelectorAll('.checkbox');
    $(allSiblingCheckboxControls).prop('checked', $(this).prop("checked")); //change all ".checkbox" checked status
}

function toggleChecked() {
    let selectCheckboxGroup = this.parentNode.parentNode;
    let mainControl = selectCheckboxGroup.querySelector('.main-control input');
    let siblingCheckboxes = selectCheckboxGroup.querySelectorAll('.checkbox');
    if (false === $(this).prop('checked')) { //if this item is unchecked
        $(mainControl).prop('checked', false); //change "select all" checked status to false
    }

    let checkedElements = [];
    let countCheckboxes = siblingCheckboxes.length;
    for(let i=0; i<countCheckboxes; i++) {
        if(true === $(siblingCheckboxes[i]).prop('checked')) {
            checkedElements.push(siblingCheckboxes[i])
        }
    }
    if(checkedElements.length === siblingCheckboxes.length) {
        $(mainControl).prop('checked', true);
    }
}

