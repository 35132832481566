let button=document.querySelector('#content_with_icons-addblock');
if(button) {
    let fields=[
        {
            type:'input',
            name:'name',
            label:'Név*',
            placeholder:'Név'
        },
        {
            type:'input',
            name:'icon_name',
            label: 'Icon neve*',
            placeholder: 'filenév#icon__id',
        },
        {
            type:'textarea',
            name:'text',
            label:'Szöveg*',
            placeholder:'Szöveg',
            class:'content',
        }
    ];
    addBlock(button,  'Szöveges Blokk', fields);
}