export function getBtnsDef(textarea) {

    let btnsDef = {
        header: headerBtnDef(),
        indent: indentBtnDef(textarea),
        outdent: outdentBtnDef(),
        price: priceBtnDef(),
        logoszOffice: officeBtnDef(),
        help: helpBtnDef(textarea),
        listLevel:listLevelBtnDef(),
        removeHeader:removeHeader(),

    }
    btnsDef = addPricesOptions(btnsDef);
    btnsDef=addOfficeOptions(btnsDef);
    return btnsDef;
}

function indentBtnDef() {
    return {
        fn: 'indent',
        title: '1 szinttel lejjebb',
        isSupported: function () {
            return !!document.queryCommandSupported && !!document.queryCommandSupported('indent');
        },
        hasIcon: true,
        forceCss: false,
    }
}

function outdentBtnDef() {
    return {
        fn: 'outdent',
        title: '1 szinttel feljebb',
        isSupported: function () {
            return !!document.queryCommandSupported && !!document.queryCommandSupported('outdent');
        },
        hasIcon: true,
    }
}

function helpBtnDef(textarea) {
    return {
        hasIcon: true,
        title: 'Súgó',
        fn: function () {
            let $modal = textarea.trumbowyg('openModal', {
                title: 'Használati útmutató',
                content: '<ol>' +
                    '<li>Az <b>"Árak megadása"</b> ikonra kattintva (csak a package_item szerkeszteőben található!) kiválaszthatod és beillesztheted az adott csomaghoz tartozó adatokat (ár,  db szám, opció, stb.) A fent kiválasztott adatok alapján a program tudni fogja, hogy mely értékeket helyettesítse majd be a weboldalon.</li>' +
                    '<li>A <b>"Link megadása"</b> ikonra kattintva linket lehet beilleszteni (pl. weboldal, email cím, telefonszám, stb.). Az "URL" mezőbe a link fajtájától függően írd az url!' +
                    '<ul>' +
                    '<li><b>Weblink formátuma:</b> https://www.tesztoldal.hu</li>' +
                    '<li><b>Email formátuma:</b> mailto:teszt.elek@gmail.NOSPA-M.com<br>Az e-mailcím védelme érdekében írd be "NOSPA-M."-t a végződés elé, valamint jelöld be az email mezőt</li>' +
                    '<li><b>Telefonszám:</b> tel:+36201234567</li>' +
                    '</ul>' +
                    '</li>' +
                    '<li>Ha új sort szeretnél kezdeni, nyomd meg a SHIFT+ENTER kombinációt! Új bekezdéshez az ENTERT-t.</li>' +
                    '</ol>',
            });
            $modal.on('tbwconfirm', function () {
                // Save data
                textarea.trumbowyg('closeModal');
            });
            $modal.on('tbwcancel', function () {
                textarea.trumbowyg('closeModal');
            });
        }
    }
}
function headerBtnDef() {
    return {
        dropdown:  ['removeHeader','h3', 'h4'],
        hasIcon: true
    }
}

function listLevelBtnDef()
{
    return {
        dropdown:['indent', 'outdent'],
        title:'Lista szint',
        hasIcon: true
    }
}

function priceBtnDef() {
    return {
        dropdown: ['company_case', 'office_services', 'option', 'stamp_piece', 'stamp_price', 'guidance', 'delivery_agent'],
        title: 'Árak megadása',
        hasIcon: true,
    }
}

function addPricesOptions(btnsDef) {
    btnsDef.company_case = createInsertHtmlButton('Cégügy (X Ft + áfa)', '[cegugy]')
    btnsDef.office_services = createInsertHtmlButton('Székhelyszolgáltatás (X ft/hó + áfa)', '[szekhelyszolgaltatas]');
    btnsDef.option = createInsertHtmlButton('Opció (pl:1/2 év)', '[option]');
    btnsDef.stamp_piece = createInsertHtmlButton('Bélyegző db szám (X db)', '[belyegzo_db]');
    btnsDef.stamp_price = createInsertHtmlButton('Bélyegző db ár (X Ft/db + áfa)', '[belyegzo_ar]');
    btnsDef.guidance = createInsertHtmlButton('Tanácsadási díj(X Ft + áfa)', '[tanacsadas]');
    btnsDef.delivery_agent = createInsertHtmlButton('Kézbesítési megbízott (X ft/hó bruttó)', '[kezbesitesi_megbizott]');
    return btnsDef;
}

function officeBtnDef() {
    return {
        dropdown:['logosz_all_office', 'logosz_country_office', 'logosz_office_cities', 'logosz_bp_offices', 'logosz_office_district'],
        title:'Iroda számlálók',
        hasIcon: true,
    }
}

function addOfficeOptions(btnsDef) {
    btnsDef.logosz_all_office=createInsertHtmlButton('Összes iroda db száma', '[logosz_all_office]');
    btnsDef.logosz_country_office=createInsertHtmlButton('Vidéki irodák db száma', '[logosz_country_offices]');
    btnsDef.logosz_office_cities=createInsertHtmlButton('Hány városban van iroda', '[logosz_city_in_office]');
    btnsDef.logosz_bp_offices=createInsertHtmlButton('Bp-i irodák db száma', '[logosz_bp_offices]');
    btnsDef.logosz_office_district=createInsertHtmlButton('Hány Bp-i kerületben van iroda', '[logosz_bp_district_offices]');
    return btnsDef;
}

function createInsertHtmlButton(title, param) {
    return {
        fn: 'insertHtml',
        title: title,
        param: param,
        hasIcon: false,
    }
}
function removeHeader()
{
    return {
        title:'Címsor eltávolítás',
        fn: 'formatBlock',
        param: 'p',
        hasIcon:true,
    }
}


