import 'trumbowyg/dist/langs/hu';
import 'trumbowyg/plugins/cleanpaste/trumbowyg.cleanpaste';
import {getBtnsDef} from "./btnsDef";

let editorButtons = [];
window.getUserTypeAjaxAndCallEditor = function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/get-user-type-for-editor',
        dataType: 'json',
        data: {},
        success: function (developer) {
            // addHtmlButton(editorButtons, developer);
            setEditor(developer);
        }
    });
}
getUserTypeAjaxAndCallEditor();

function setEditor(developer) {
    $('textarea:not(.no-editor)').each(function () {
        setDefaultButtons(editorButtons, developer);
        addClassDependsButtons(editorButtons, $(this));
        $(this).trumbowyg({
            lang: 'hu',
            svgPath: '/svg/icons.svg',
            btnsDef: getBtnsDef($(this)),
            btns: editorButtons,
            autogrow: true,
            fixedBtnPane: true,
            semantic: false,
            removeformatPasted: true
        }).on('tbwpaste', function () {
            removePastedOrderedListSign($(this), $(this).trumbowyg('html'));
            removeEmptyTagsAfterPaste($(this), $(this).trumbowyg('html'));

        }).on('tbwchange', function () {
            removeIndentWhenNotList($(this), $(this).trumbowyg('html'));
        });
    })
}

function setDefaultButtons(editorButtons, developer) {
    // let is_developer = false;
    // for (let i = 0; i < editorButtons.length; i++) {
    //     if (editorButtons[i].find(element => element === 'viewHTML')) {
    //         is_developer = true;
    //     }
    // }
    editorButtons.length = 0;
    editorButtons.push(
        ['bold', 'italic', 'underline'],
        ['horizontalRule'],
        ['unorderedList', 'orderedList', 'listLevel'],
        ['link'],
        ['header'],
        ['undo', 'redo'],
        ['help'],
        ['viewHTML'],
        ['fullscreen']
    );
    // if(developer) editorButtons.push(['viewHTML'])
}
function addClassDependsButtons(editorButtons, textarea) {

    if (textarea.hasClass('package')) editorButtons.push(['price']);
    if (textarea.hasClass('office')) editorButtons.push(['logoszOffice']);
}

function removePastedOrderedListSign(field, html) {
    if (html.search('•') >= 0) {
        let newHtml = html.replace(/•/g, '');
        field.trumbowyg('html', newHtml);
    }
}

function removeIndentWhenNotList(field, html) {

    if (html.search('<blockquote') >= 0) {
        let newHtml = html.replace(/<blockquote style=".*">/, '');
        newHtml = newHtml.replace('</blockquote>', '');
        field.trumbowyg('html', newHtml);
    }
}

function removeEmptyTagsAfterPaste(field, html) {
    let newHtml = html.replaceAll('<p></p>', '');
    field.trumbowyg('html', newHtml);
}
