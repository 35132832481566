$(function () {
    $('.item-orders').sortable({
        header: "> li > p",
        handle: "p",
    });

    function getItemValues() {
        let itemsData = [];
        $('.item-orders>li').each(function () {
            itemsData.push({item_type_name: $(this).data('itemType'), id: $(this).attr('id')});
        });
        return itemsData;
    }

    $('#new-item-order').on('click', function () {
        $('#item_order_error').empty().removeClass('alert-danger');
        itemOrderAjax(getItemValues());
    });
});

function itemOrderAjax(data) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '',
        dataType: 'json',
        data: {itemOrders: data},
        success: function (data) {
            if (data.hasOwnProperty('errorMessages')) {
                setErrorMessages(data.errorMessages);
            }
            else {
                window.location=document.referrer;
            }
        }
    });
}

function setErrorMessages(errorMessages) {
    let errorDiv = $('#item_order_error');
    errorDiv.addClass('alert-danger');
    let errorMessageList = $('<ul>');
    let iterationLength = errorMessages.length;
    for (let i = 0; i < iterationLength; i++) {
        let listItem = $('<li>').html(errorMessages[i]);
        errorMessageList.append(listItem);
    }
    errorDiv.append(errorMessageList);
}