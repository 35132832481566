let button = document.querySelector('#officeType-addblock');
if (button) {
    let fields = [
        {
            type: 'input',
            name: 'name',
            label: 'Név*',
            placeholder:'Név'
        },
    ];
    addBlock(button, 'Feladatkör', fields);
}