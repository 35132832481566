if(document.querySelector('#extra_service_select')) {
    let citySelect=document.querySelector('#city_id');
    citySelect.addEventListener('change', function() {
        document.querySelector('#service_select').innerHTML=''
        if(this.value) {
            getServicesAjax(this.value);
        }
    });
    if(citySelect.value) {
        let oldServiceId=parseInt(document.querySelector('#old_service_id').value);
        getServicesAjax(citySelect.value, oldServiceId);
    }
}
function getServicesAjax(cityId, oldServiceId=null)
{
        $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type: 'POST',
                url: '/item/price_informative/get-services',
                dataType: 'json',
                data:{city_id:cityId} ,
                success: function (services) {
                    createServicesSelect(services, oldServiceId);
                    priceInformativeServiceSelect();
                }
        
            });
}
function createServicesSelect(services, oldServiceId)
{
    let selectDiv=document.querySelector('#service_select');
    let selectLabel=createAnyElement('label', {for:'service_id'}, 'Szolgáltatás választás');
    let select=createAnyElement('select', {id:'service_id', name:'service_id', class:'form-control'});
    select.append(createAnyElement('option', {value:''}, '---VÁLASSZON---'));
    let iterationLength=services.length;
    for(let i=0; i<iterationLength; i++)
    {
        let option=createAnyElement('option', {value:services[i].id}, services[i].name);
        if(services[i].id===oldServiceId) option.setAttribute('selected', true);
        select.append(option);
    }
    selectDiv.append(selectLabel, select);
}